<template>
    <div class="trades-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport" :disabled="processingExport">
                    <b-spinner small v-if="processingExport"></b-spinner>
                    Export
                </b-button>
                <b-button v-b-modal.tradesColumnsModal variant="light">
                    Columns
                </b-button>
            </div>
        </div>
        <b-table id="trades-table" :items="filterItems()" :fields="getEnabledColumns()" responsive class="text-center table-details"
            show-empty :per-page="50" :current-page="currentPage" sort-by="exitTime" :sort-desc="true"
            @row-clicked="onRowClick">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                <span id="defaultText">Loading Trade Details...</span>
            </template>
            <template v-slot:row-details="row">
                <template v-if="!tradeDetails.hasOwnProperty(row.item.account) || !tradeDetails[row.item.account].hasOwnProperty(row.item.id)">
                    Loading trade details...
                </template>
                <template v-else>
                    <div class="row">
                        <div class="col-12">
                            <b-table :items="tradeDetails[row.item.account][row.item.id].Executions" :fields="tradeDetailsColumns" responsive></b-table>
                        </div>
                    </div>
                </template>
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="50" aria-controls="trades-table"></b-pagination>
            </div>
        </div>
        <b-modal id="tradesColumnsModal" title="Customise Columns" body-class="p-0" @show="setupColumnFiltersModal" @ok="finishColumnFilters" @hidden="resetColumnFilters">
            <draggable v-model="filteredColumns" :force-fallback="true" :fallback-tolerance="10">
                <div v-for="column in filteredColumns" :key="column.key" class="column-filter" @click="onColumnFilterClick($event, column)">
                    <div class="d-flex align-items-center justify-content-between">
                        <b-form-checkbox v-model="column.enabled" :value="true" :unchecked-value="false">
                            {{column.label}}
                        </b-form-checkbox>
                        <span class="drag-handle">
                            <i class="mdi mdi-menu"></i>
                        </span>
                    </div>
                </div>
            </draggable>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-row align-items-center justify-content-start">
                            <b-button variant="primary" @click="ok()">Confirm</b-button>
                            <b-button variant="secondary" @click="cancel()" class="ml-2">Cancel</b-button>
                        </div>
                        <div class="col-md-6 d-flex flex-row align-items-center justify-content-end">
                            <b-checkbox v-model="columnFiltersSave" :value="true" :unchecked-value="false">
                                Save as preset
                            </b-checkbox>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
        <b-modal v-model="showTradeDetails" size="xl" title="Trade Details">
            <template v-if="!loadedTradeDetails">
                <p class="text-center">Loading trade details...</p>
            </template>
            <template v-else>
                <div class="row">
                    <div class="col-12 mt-3">
                        <h5>Executions</h5>
                        <b-table class="text-center" :items="tradeDetails.Executions" :fields="tradeDetailsColumns" sort-by="DateTime" responsive>
                            <template v-slot:cell(ToleranceTicks)="data">
                                <span v-if="parseFloat(data.value) < 0" style="color: red;">
                                    {{data.value}}
                                </span>
                                <span v-else>
                                    {{data.value}}
                                </span>
                            </template>
                        </b-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <h5>Platform Speed</h5>
                        <chart v-if="chartOptions.series.length > 0" :chartOptions="chartOptions"></chart>
                    </div>
                </div>
            </template>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="primary" @click="cancel()" class="ml-auto">Close</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { map, clone } from 'lodash';
import { parse, format } from 'date-fns';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart';

export default {
    name: 'trades',
    data() {
        let that = this;

        let columns = [];

        if(this.$store.state.auth.user.internal) {
            columns = [
                {
                    key: 'internalStrategy',
                    label: 'Internal Strategy',
                    sortable: true,
                    enabled: false,
                    tdClass: 'internalStrategyCell'
                },
                {
                    key: 'strategy',
                    label: 'Strategy',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'instrument',
                    label: 'Instrument',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'tradeDirection',
                    label: 'Position',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'size',
                    label: 'Size',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0);
                    }
                },
                {
                    key: 'entryPrice',
                    label: 'Entry',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'exitPrice',
                    label: 'Exit',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        if(parseFloat(value) === 0) {
                            return '-';
                        }

                        return value;
                    }
                },
                {
                    key: 'returnBps',
                    label: 'Return (Bps)',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                     key: 'tradeReturn',
                     label: 'Trade Return',
                     sortable: true,
                     enabled: false,
                     formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                     } //uncommented this
                 },
                {
                    key: 'fundReturn',
                    label: 'Return (%)',
                    sortable: true,
                    enabled: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'returnOnCapital',
                    label: 'Return On Capital',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                // {
                //     key: 'cashReturn',
                //     label: 'Cash Return',
                //     sortable: true,
                //     enabled: false,
                //     formatter: function(value) {
                //         return that.$options.filters.currency(that.$options.filters.number(value));
                //     }
                // },
                // {
                //     key: 'cashReturnSinceMarking',
                //     label: 'Cash Return MTM',
                //     sortable: true,
                //     enabled: false,
                //     formatter: function(value) {
                //         return that.$options.filters.currency(that.$options.filters.number(value));
                //     }
                // },
                {
                    key: 'tickReturn',
                    label: 'Tick Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickReturnSinceMarking',
                    label: 'Tick Return MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickSize',
                    label: 'Tick Size',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'hourlyTimeWeightedReturn',
                    label: 'Hourly Time Weighted Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                    }
                },
                {
                    key: 'volume',
                    label: 'Volume',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'entryTime',
                    label: 'Entry Time',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {
                    key: 'exitTime',
                    label: 'Exit Time',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        let formattedDate = format(value, 'dd/MM/yyyy HH:mm:ss');

                        if(formattedDate === '31/12/9999 23:59:59') {
                            return '-';
                        }

                        return formattedDate;
                    }
                },
                {
                    key: 'exitReason',
                    label: 'Exit Reason',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return value === 'Unknown' ? '' : value;
                    }
                }
            ];
        } else {
            columns = [
                {
                    key: 'strategy',
                    label: 'Strategy',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'instrument',
                    label: 'Instrument',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'tradeDirection',
                    label: 'Position',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'size',
                    label: 'Size',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0);
                    }
                },
                {
                    key: 'entryPrice',
                    label: 'Entry',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'exitPrice',
                    label: 'Exit',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        if(parseFloat(value) === 0) {
                            return '-';
                        }

                        return value;
                    }
                },
                {
                    key: 'returnBps',
                    label: 'Return (Bps)',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                 {
                     key: 'tradeReturn',
                     label: 'Trade Return',
                     sortable: true,
                     enabled: false,
                     formatter: function(value) {
                         return that.$options.filters.percentage(that.$options.filters.number(value));
                     }
                 },
                {
                    key: 'fundReturn',
                    label: 'Return (%)',
                    sortable: true,
                    enabled: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                // {
                //     key: 'cashReturn',
                //     label: 'Cash Return',
                //     sortable: true,
                //     enabled: false,
                //     formatter: function(value) {
                //         return that.$options.filters.currency(that.$options.filters.number(value));
                //     }
                // },
                // {
                //     key: 'cashReturnSinceMarking',
                //     label: 'Cash Return MTM',
                //     sortable: true,
                //     enabled: false,
                //     formatter: function(value) {
                //         return that.$options.filters.currency(that.$options.filters.number(value));
                //     }
                // },
                {
                    key: 'tickReturn',
                    label: 'Tick Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickReturnSinceMarking',
                    label: 'Tick Return MTM',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.number(value, 0)
                    }
                },
                {
                    key: 'tickSize',
                    label: 'Tick Size',
                    sortable: true,
                    enabled: false
                },
                {
                    key: 'returnOnCapital',
                    label: 'Return On Capital',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'hourlyTimeWeightedReturn',
                    label: 'Hourly Time Weighted Return',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value, 2, true));
                    }
                },
                {
                    key: 'volume',
                    label: 'Volume',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return that.$options.filters.currency(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'entryTime',
                    label: 'Entry Time',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {
                    key: 'exitTime',
                    label: 'Exit Time',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        let formattedDate = format(value, 'dd/MM/yyyy HH:mm:ss');

                        if(formattedDate === '31/12/9999 23:59:59') {
                            return '-';
                        }

                        return formattedDate;
                    }
                },
                {
                    key: 'exitReason',
                    label: 'Exit Reason',
                    sortable: true,
                    enabled: false,
                    formatter: function(value) {
                        return value === 'Unknown' ? '' : value;
                    }
                }
            ];
        }

        let sortedColumns = [];

        let loadedColumns = localStorage.getItem('tradesColumns');
        if(loadedColumns !== null) {
            loadedColumns = JSON.parse(loadedColumns);

            for(let key in loadedColumns) {
                for(let column of columns) {
                    if(column.key === key) {
                        column.enabled = loadedColumns[key];
                        sortedColumns.push(column);
                        break;
                    }
                }
            }

            for(let column of columns) {
                if(!loadedColumns.hasOwnProperty(column.key)) {
                    sortedColumns.push(column);
                }
            }
        } else {
            for(let column of columns) {
                column.enabled = true;
            }

            sortedColumns = columns;
        }

        let filters = {};
        for(let column of sortedColumns) {
            filters[column.key] = '';
        }

        return {
            columns: sortedColumns,
            processingExport: false,
            items: [],
            filters: filters,
            columnFiltersSave: true,
            filteredColumns: [],
            currentPage: 1,
            hasSetupListeners: false,
            loadedTradeDetails: false,
            showTradeDetails: false,
            selectedTrade: {},
            tradeDetails: {},
            tradeDetailsColumns: [
                {
                    key: 'DateTime',
                    label: 'Timestamp',
                    sortable: true,
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {
                    key: 'Strategy',
                    label: 'Strategy',
                    sortable: true
                },
                {
                    key: 'Symbol',
                    label: 'Symbol',
                    sortable: true
                },
                {
                    key: 'TradeType',
                    label: 'TradeType',
                    sortable: true
                },
                {
                    key: 'RequestedSize',
                    label: 'Requested Size',
                    sortable: true
                },
                {
                    key: 'RequestedPrice',
                    label: 'Requested Price',
                    sortable: true
                },
                {
                    key: 'FilledSize',
                    label: 'Filled Size',
                    sortable: true
                },
                {
                    key: 'FilledPrice',
                    label: 'Filled Price',
                    sortable: true
                },
                {


                    
                    key: 'ReturnBps',
                    label: 'Return (Bps)',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                 {
                    key: 'FundReturn',
                    label: 'Return (%)',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.percentage(that.$options.filters.number(value));
                    }
                },
                {
                    key: 'ToleranceTicks',
                    label: 'Tolerance (Ticks)',
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                },
                {
                    key: 'TriggeredBy',
                    label: 'Triggered By',
                    sortable: true
                }
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 200
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: [
                        'Entry',
                        'Exit'
                    ]
                },
                yAxis: {
                    reversedStacks: false,
                    title: {
                        text: 'Platform Speed (ms)'
                    },
                    labels: {
                        style: {
                            whiteSpace: 'nowrap'
                        }
                    }
                },
                tooltip: {
                    valueDecimals: 0
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        stacking: 'normal'
                    }
                },
                series: []
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    components: {
        draggable,
        chart
    },
    mounted() {
        this.requests(true);
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('trades', this.onTrades);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('trades', this.onTrades);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'Trades',
                args: {}
            });
        },
        onTrades(event) {
            if(document.getElementById("defaultText")){document.getElementById("defaultText").innerHTML = "No results found"};
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(event.request.accountName !== account) {
                return;
            }

            let date = new Date();

            for(let trade of event.response) {
                trade.entryTime = parse(trade.entryTime, 'dd/MM/yyyy HH:mm:ss', date);
                trade.exitTime = parse(trade.exitTime, 'dd/MM/yyyy HH:mm:ss', date);
            }

            this.items = event.response;
        },
        getEnabledColumns() {
            let columns = [];

            for(let column of this.columns) {
                if(column.enabled) {
                    columns.push(column);
                }
            }

            return columns;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onColumnFilterClick(event, column) {
            let tagName = event.target.tagName.toLowerCase();

            if(tagName === 'input' || tagName === 'label') {
                return;
            }

            column.enabled = !column.enabled;
        },
        setupColumnFiltersModal() {
            this.filteredColumns = map(this.columns, clone);
        },
        finishColumnFilters() {
            this.columns = map(this.filteredColumns, clone);

            if(this.columnFiltersSave) {
                let columns = {};
                for(let column of this.columns) {
                    columns[column.key] = column.enabled;
                }

                localStorage.setItem('tradesColumns', JSON.stringify(columns));
            }
        },
        resetColumnFilters() {
            this.filteredColumns = [];
        },
        onTradeDetails(event) {
            let executions = [];
            for(let execution of event.response.Executions) {
                execution.DateTime = new Date(execution.DateTime);

                if(execution.FilledPrice != 0 && execution.FilledSize != 0) {
                    executions.push(execution);
                }
            }

            event.response.Executions = executions;

            this.tradeDetails = event.response;

            if(!this.loadedTradeDetails) {
                this.loadedTradeDetails = true;
            }

            this.chartOptions.series = [];

            let max = 100;
            
            let totals = {};
            for(let key in event.response.PlatformTiming) {
                let series = {
                    name: key,
                    data: []
                };

                for(let component in event.response.PlatformTiming[key]) {
                    let value = parseFloat(event.response.PlatformTiming[key][component]);

                    series.data.push({
                        name: component,
                        y: value
                    });

                    if(!totals.hasOwnProperty(component)) {
                        totals[component] = 0;
                    }

                    totals[component] += value;
                }

                this.chartOptions.series.push(series);
            }
            
            let total = 0;
            for(let key in totals) {
                if(totals[key] > total) {
                    total = totals[key];
                }
            }

            let ceilValue = Math.ceil(total);

            if(ceilValue > max) {
                max = ceilValue;
            } else if(ceilValue === max) {
                max += 10;
            }

            if(max !== this.chartOptions.yAxis.max) {
                this.chartOptions.yAxis.max = max;
            }
        },
        onRowClick(item) {
            this.showTradeDetails = !this.showTradeDetails;
            if(this.showTradeDetails) {
                this.loadedTradeDetails = false;

                this.selectedTrade = item;
                this.$store.commit('setSelectedTrade', item);

                let account = this.account;
                if(!account) {
                    account = this.$store.state.activeAccount;
                }

                this.$ws.once('tradedetails', this.onTradeDetails);
                this.$ws.send({
                    accountName: account,
                    request: 'TradeDetails',
                    args: {
                        tradeID: item.id,
                        accountName: item.account
                    }
                });
            }
        },
        getPriceChartInterval() {
            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            if(this.$store.getters.isHftAccount(account)) {
                return '1';
            }

            return '30';
        },
        onExport() {
            this.processingExport = true;

            try {
                let headers = {};

                for(let column of this.columns) {
                    headers[column.key] = column.label;
                }

                let trades = map(this.items, clone);
                for(let trade of trades) {
                    trade.entryTime = format(trade.entryTime, 'yyyy-MM-dd HH:mm:ss');
                    
                    if(trade.exitReason === 'Unknown') {
                        trade.exitReason = '';
                    }

                    if(trade.exitPrice === 0) {
                        trade.exitPrice = '-';
                        trade.exitTime = '-';
                    } else {
                        trade.exitTime = format(trade.exitTime, 'yyyy-MM-dd HH:mm:ss');
                    }
                }

                exportCsv(headers, trades, 'Trades');
            } catch(err) {}

            this.processingExport = false;
        }
    }
}
</script>