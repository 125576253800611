<template>
    <div id="chart">
        <div class="col-12">
                <div :id="'container'+chartID" ></div>
        </div>
    </div>
</template>

<script>
import Highcharts, { arrayMax } from 'highcharts'
//exporting
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";
exporting(Highcharts);
offlineExporting(Highcharts);


export default {
    data(){
        let that = this
        let exportingConfig = {
            filename: "chart",
            sourceWidth: "1500",
            sourceHeight: "1000",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:""}
            },
           
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        return{
             chart:  {},
             exportingOptions: exportingConfig,
        }
    },
    props: {
        chartOptions: {
            type: Object,
            default: null
        },
        height: {
            type: String,
            default: "500px"
        },
        chartID:{
            type: String,
            default: "0"
        },
    },
        mounted(){
            if(this.chartOptions != null){
                let chartOptions = this.chartOptions;
                //create chart as a variable
                if(!chartOptions.hasOwnProperty("exporting")){chartOptions.exporting = {}}
                if(!(chartOptions.yAxis instanceof Array) && chartOptions.yAxis.hasOwnProperty("title")){
                    this.exportingOptions.filename = chartOptions.yAxis.title.text.replace(" ", "") || "chart";
                    this.exportingOptions.chartOptions.title.text = chartOptions.yAxis.title.text || "title";
                }
                chartOptions["exporting"] = {...this.exportingOptions, ...chartOptions["exporting"]};

                this.chart = new Highcharts.chart('container'+this.chartID, chartOptions)
                
                this.$store.commit('addToCharts',{chart:this.chart, chartID:this.chartID});

                this.$emit("getSVG", this.chart.getSVG(), this.chartID);
            }
            
    
        },
        methods:{
            onExport(){
                 this.chart.exportChart({type: 'image/svg+xml'});
            },
        }
}
</script>