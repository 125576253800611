import Highcharts from 'highcharts'
import store from '@/store/index'

export function convertToCsv(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    let headers = {};

    if (Object.keys(array).length > 0) {
        headers = array[0];
    }

    for (let i = 0, keys = Object.keys(array), length = keys.length; i < length; i++) {
        let line = '';

        for (let j = 0, jKeys = Object.keys(headers), jLength = jKeys.length; j < jLength; j++) {
            if (line !== '') {
                line += ',';
            }

            line += array[keys[i]][jKeys[j]];
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportCsv(headers, rows, name = 'export') {
    if(headers) {
        rows.unshift(headers);
    }

    let json = JSON.stringify(rows);

    let csv = convertToCsv(json);

    let filename = `${name}.csv`;

    let blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    if(navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
    } else {
        let link = document.createElement('a');
        if(typeof link.download !== 'undefined') {
            let url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

Highcharts.getSVGHistograms = function(charts,exportData){
    var svgArr = [],
    top = 0,
    width = 0
    charts.forEach((chart, i)=>{
        var svg = chart.getSVG();
        let data = exportData.find(x=>x.id == i);
        width = Math.max(width, chart.chartWidth*2);
        let chartWidth = chart.chartWidth;
        let chartHeight = 1;
        svg = svg.replace('<svg', `<g transform="translate(${i%2==0 ? 0 : (chartWidth)},${top}),scale(0.5,${chartHeight})" `);
        svg = svg.replace('</svg>', '</g>');
        if(i%2!=0){
            top += (chart.chartHeight*1.3)*(chartHeight);
        }
        svgArr.push(svg);
    });
    return `<svg height=${top} width=${width} version="1.1" xmlns="http://www.w3.org/2000/svg">${svgArr.join('')}</svg>`;
    
}

Highcharts.getSVG = function(charts,exportData){
    var svgArr = [],
    top = 0,
    width = 0,
    txt;
    charts.forEach((chart, i)=>{
        var svg = chart.getSVG();
        let data = exportData.find(x=>x.id == i);
        let pre = data.title
        let post = data.description
        width = Math.max(width, chart.chartWidth);
        if(pre != null){
            //pre chart text
            txt = pre;
            txt = `<text x=${(width/2)-(txt.length*2.5)} y =${top+20}>${txt}</text>`;
            top += 40;
            svgArr.push(txt);
        }
        svg = svg.replace('<svg', `<g transform="translate(${data.scaleX == 1 ? data.scaleX :((1-data.scaleX)*width)/2},${top}),scale(${data.scaleX},${data.scaleY})" `);
        svg = svg.replace('</svg>', '</g>');
        top += (chart.chartHeight*1.3)*data.scaleY;
        svgArr.push(svg);
        if(post != null){
            //post chart text
            txt = post;
            txt = `<text x=${width/2-txt.length} y =${top+20}>${txt}</text>`;
            
            top += 60;
            svgArr.push(txt);
        }
    });
    return `<svg height=${top} width=${width} version="1.1" xmlns="http://www.w3.org/2000/svg">${svgArr.join('')}</svg>`;
    
}

export function exportChartWithText(charts, data, options, histograms = false){
    // Merge the options
    options = Highcharts.merge(Highcharts.getOptions().exporting, options);

    // Post to export server
    Highcharts.post(options.url, {
    filename: options.filename || 'chart',
    type: options.type,
    width: options.width,
    svg: histograms ? Highcharts.getSVGHistograms(charts, data) : Highcharts.getSVG(charts, data)
    });
}